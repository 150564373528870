.wrapper {
  position: relative;
}

.counter {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  background-color: var(--primary-background-color);
  border: 1px solid var(--divider-color);
  padding: 0 0.25rem;
  min-width: 0.5rem;
  border-radius: 0.5rem;
}