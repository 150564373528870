.wrapper {
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  display: block;
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    position: absolute;
    border: 0;
  }
}
