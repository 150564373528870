@use "@/styles/typography/label" as *;
@use "@/styles/typography/title-regular" as *;

.separator {
  background-color: var(--divider-color);
  border: 0;
  height: 1px;
  margin: 0;
}

.timestamp {
  margin-top: 0.75rem !important;
}

.timeAgo {
  @include label;
  text-transform: none
}

.time {
  @extend .title-8-regular;
  color: var(--label-text-color);
  margin-left: 0.5rem
}
