@use "@/styles/mixins/card-outline" as *;
@use "@/styles/mixins/media-queries" as *;

.matchCard {
  @include card-outline;
  display: block;
  text-decoration: none;
  border: 1px solid var(--divider-color);
  border-radius: var(--rounded-corner);
  filter: drop-shadow(0 0 1em rgba(0, 0, 0, 0.04));
  overflow: hidden;
}

:global {
  @include dark-mode {
    :local {
      .matchCard {
        background-color: var(--elevated-background-color);
      }
    }
  }
}
