@use "@/styles/typography/text" as *;

.container {
  display: flex;
  justify-content: center;
}

.tweet {
  // sometimes link text is too long and does not fit on the screen
  word-break: break-word;
}
