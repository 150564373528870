@use "@/styles/mixins/media-queries" as *;
@use "@/styles/typography/title-bold" as *;
@use "@/styles/mixins/max-root-width";

.relatedVideos {
  @include max-root-width.limit;
}

.relatedVideos__header {
  @extend .title-7-bold;
  text-transform: uppercase;
}

.relatedVideos__grid {
  margin-top: 1.25rem;
  display: grid;

  @include mobile-only {
    gap: 1.5rem;
  }

  @include tablet-portrait-and-up {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include tablet-portrait-only {
    gap: 2rem 1rem;
  }

  @include tablet-landscape-only {
    gap: 1.625rem;
  }

  @include desktop-and-up {
    gap: 2.5rem;
  }

  @media (hover: hover) {
    // TODO:a11y: this is bad but product & design insists on this WEB-2181
    &:hover > .relatedVideos__gridItem:not(:hover) {
      opacity: 0.5;
    }
  }
}

.relatedVideos__gridItem {
  transition: opacity 300ms ease-in-out;
}
