@use "@/styles/mixins/media-queries" as *;
@use "@/styles/typography/text" as *;
@use "@/styles/mixins/card-outline" as *;
@use "@/styles/typography/title-bold" as *;
@use "@/styles/mixins/ellipsis" as *;
@use "@/styles/mixins/line-clamp" as *;

.container {
  @include card-outline;
  display: grid;
  padding: 1.2rem;
  border-radius: var(--rounded-corner);
  border: 1px solid var(--divider-color);
  background-color: var(--primary-background-color);
  text-decoration: none;
  height: 100%;

  @include desktop-and-up {
    gap: 1.25em;
  }
}

:global {
  @include dark-mode {
    :local {
      .container {
        background-color: var(--elevated-background-color);
      }
    }
  }
}

.content {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  align-items: center;
}

.logoRounded {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
}

.titles {
  display: grid;
  grid-template-rows: min-content;
}

.title {
  @include line-clamp(2);

  @include tablet-landscape-and-up {
    @include line-clamp(1);
  }
}

.subtitle {
  @include ellipsis();
  color: var(--label-text-color);
}
