@use "@/styles/mixins/media-queries" as *;
@use "@/styles/typography/text" as *;
@use "@/styles/mixins/card-outline" as *;
@use "@/styles/typography/title-bold" as *;
@use "@/styles/mixins/ellipsis" as *;
@use "@/styles/mixins/line-clamp" as *;

.container {
  @include card-outline;

  display: grid;
  grid-template-rows: min-content;
  width: fit-content;
  gap: 0.625rem;
  padding: 0.625rem;
  background-color: var(--elevated-background-color);
  border-radius: var(--rounded-corner);
  border: 1px solid var(--elevated-background-color);
  filter: drop-shadow(0 0 1em rgba(0, 0, 0, 0.04));
  text-decoration: none;
  color: unset;

  @include desktop-and-up {
    gap: 1.25rem;
  }
}

.containerLarge {
  background-color: var(--primary-background-color);
  width: auto;
  height: 100%;
  padding: 1.25rem;

  @include tablet-landscape-and-up {
    padding: 0
  }
}

:global {
  @include dark-mode {
    :local {
      .entityChipLinkLarge {
        background-color: var(--elevated-background-color);
      }
    }
  }
}

.logo {
  margin-inline-end: 0.625rem;
  min-width: 1.25rem;
  min-height: 1.25rem;
}

.logoLarge {
  margin-right: 1.25rem;
  min-width: 2.5rem;
  min-height: 2.5rem;

  @include mobile-only {
    margin-bottom: 0.625rem;
  }

  @include tablet-portrait-only {
    margin-bottom: 0.625rem;
  }
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contentLarge {
  display: flex;

  @include tablet-portrait-only {
    flex-direction: column;
    align-items: flex-start;
  }

  @include mobile-only {
    flex-direction: column;
    align-items: flex-start;
  }
}

.titles {
  display: grid;
  grid-template-rows: min-content;
  gap: 0.25em;
}

.title {
  margin: 0;
}

.titleLarge {
  @include line-clamp(2);

  @include tablet-landscape-and-up {
    @include line-clamp(1);
  }
}