@use "@/styles/mixins/max-root-width";
@use "@/styles/typography/title-bold" as *;

.container {
  @include max-root-width.limit;
  width: 100%;
}

.title {
  text-transform: uppercase;
  margin-bottom: 1.25rem;
}

.list {
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  gap: 0.625rem;
}