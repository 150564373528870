@use "@/styles/typography/text" as *;

.ofList {
  list-style-position: inside;
}

.ofListUnordered {
  @extend .ofList;
  list-style-type: disc;
}

.ofListOrdered {
  @extend .ofList;
  list-style-type: decimal;
}

.ofList__item {
  @extend .text-1;
  word-break: break-word;
  position: relative;
  margin-top: 1.25rem;
}

