@use "fake-borders-pseudo-element" as *;

// reusable hover/focus effects for card-like elements
@mixin card-outline($border-width: 3px) {
  filter: drop-shadow(0px 0.25em 0.5em rgba(0, 0, 0, 0.05));

  @media (hover: hover) {
    --transition-timing: 300ms;
    transition: transform var(--transition-timing) ease-in-out;
    position: relative;
    outline: none; // there are enough visual indicators without outline
    cursor: pointer;

    &:focus,
    &:hover {
      transform: translateY(-0.375em);
      filter: drop-shadow(0px 0.625em 0.625em rgba(0, 0, 0, 0.05));
    }

    &:after {
      @include fake-borders-pseudo-element(
                      $border-width,
                      var(--label-text-color)
      );
      opacity: 0;
      visibility: hidden;
      transition: opacity var(--transition-timing) ease-in-out,
      visibility var(--transition-timing) ease-in-out;
    }

    &:focus:after,
    &:hover:after {
      opacity: 1;
      visibility: visible;
    }
  }
}