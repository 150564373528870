@use "@/styles/mixins/media-queries" as *;
@use "@/styles/mixins/ellipsis" as *;

.articleHeroBanner {
  hyphens: auto;
  display: flex;
  flex-direction: column;
  background-color: var(--color-background);

  @include tablet-portrait-and-up {
    flex-direction: row;

    :dir(rtl) & {
      flex-direction: row-reverse;
    }
  }
}

:global {
  @include dark-mode() {
    :local {
      .articleHeroBanner {
        --color-pattern: var(--color-pattern-dark);
        --color-text: var(--color-text-dark);
        --color-background: var(--color-background-dark);
      }
    }
  }

  @include light-mode() {
    :local {
      .articleHeroBanner {
        --color-pattern: var(--color-pattern-light);
        --color-text: var(--color-text-light);
        --color-background: var(--color-background-light);
      }
    }
  }
}

// styles for case when article doesn't have image
.noImage {
  height: auto;

  .heroBannerImageComponent {
    display: none;
  }

  @include tablet-portrait-and-up {
    position: relative;

    .heroBannerImageComponent {
      display: block;
      height: auto;
      flex-basis: 30%;
    }

    .heroBannerTextWrapper {
      padding-top: 2.5rem;
      padding-bottom: 1.5rem;
    }

    .heroBannerTextComponent {
      position: static;
      flex-basis: 70%;
    }

    .patternContainerLeft {
      right: unset;
      width: 40%
    }
  }
}

.patternContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.patternContainerRight {
  display: none;

  @include tablet-portrait-and-up {
    display: block;
    position: static;
    width: var(--root-side-padding);
    padding-top: 2rem;

    path {
      fill: var(--color-pattern);
    }
  }

  @include desktop-and-up {
    padding-top: 3.5rem;
  }

  ;
}

.pattern {
  height: 100%;
  width: 100%;
}

// styles for text part
.heroBannerTextComponent {
  position: relative;
  padding-top: 1.25rem;
  padding-left: var(--root-side-padding);

  path {
    fill: var(--color-pattern);
  }

  @include tablet-portrait-and-up {
    flex-basis: 43%;
    padding-top: 2rem;
    padding-left: calc(var(--root-side-padding) - 1.25rem);
  }

  @include desktop-and-up {
    flex-basis: 38%;
    padding-top: 3.5rem;
    padding-left: calc(var(--root-side-padding) - 2rem);
  }
}

.heroBannerTextWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1.25rem;
  background: var(--color-background);
  z-index: var(--article-header-z-index);

  @include tablet-portrait-and-up {
    height: 100%;

    :dir(rtl) & {
      position: absolute;
      top: 1px;
      transform: translateX(55vw);
    }
  }

  @include desktop-and-up {
    padding-left: 2rem;
  }
}

.provider {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  --logo-size: 1.8125rem;

  @include tablet-portrait-and-up {
    --logo-size: 2rem;
  }

  @include desktop-and-up {
    margin-bottom: 1.375rem;

    --logo-size: 2.5rem;
  }
}

.providerLogo {
  width: var(--logo-size);
  height: var(--logo-size);
  margin-inline-end: 0.5rem;

  @include desktop-and-up {
    margin-inline-end: 0.75rem;
  }
}

.providerDetails {
  display: flex;
  align-items: center;
}

.providerDelimiter {
  margin: 0 0.375rem;
}

.articleTitle {
  color: var(--color-text);

  @include tablet-portrait-and-up {
    position: relative;
    margin-inline-end: -10rem; // negative value to allow text overlay the image
    overflow-y: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
  }

  @include desktop-and-up {
    margin-inline-end: -15.625rem;
    -webkit-line-clamp: 5;
  }
}

.liveIndicator {
  margin-right: 0.5rem;
}

.articleTitleTextBackground {
  position: relative;
  background-color: var(--color-background);
  box-shadow: 1.25rem 0 0 var(--color-background);
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}

// styles for image part
.heroBannerImageComponent {
  flex: auto;
  height: 100%;
  position: relative;

  img {
    border-radius: unset;
  }

  @include tablet-portrait-and-up {
    padding-top: 2rem;

    :dir(rtl) & {
      transform: translateX(-55%);
    }
  }

  @include desktop-and-up {
    padding-top: 3.5rem;
  }
}

.articleCta {
  display: grid;
  grid-auto-flow: column;
  margin-top: 1rem;
  gap: 1em;
  grid-template-columns: repeat(auto-fill, 3.125rem);

  @include desktop-and-up {
    margin-top: 1.375rem;
  }
}