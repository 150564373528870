@use "title-regular" as *;
@use "../mixins/font" as font;

.title-1-medium {
  @extend .title-1-regular;
  @include font.medium;
}

.title-2-medium {
  @extend .title-2-regular;
  @include font.medium;
}

.title-3-medium {
  @extend .title-3-regular;
  @include font.medium;
}

.title-4-medium {
  @extend .title-4-regular;
  @include font.medium;
}

.title-5-medium {
  @extend .title-5-regular;
  @include font.medium;
}

.title-6-medium {
  @extend .title-6-regular;
  @include font.medium;
}

.title-7-medium {
  @extend .title-7-regular;
  @include font.medium;
}

.title-8-medium {
  @extend .title-8-regular;
  @include font.medium;
}

.title-9-medium {
  @extend .title-9-regular;
  @include font.medium;
}
