@use "@/styles/mixins/media-queries" as *;
@use "@/styles/typography/text" as *;

.container {
  padding: 1rem;
  margin-top: 2rem;
}

.adWrapper {
  --padding-size: 2rem;
  padding: var(--padding-size) 0;
  background: var(--elevated-background-color);

  @include mobile-only {
    margin-left: calc(-1 * var(--root-side-padding));
    margin-right: calc(-1 * var(--root-side-padding));
  }
}

.dialogWrapper {
  padding: 2.5rem 1.5rem;
  width: 23.43rem;
  background: var(--primary-background-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: var(--rounded-corner);
  border: 1px solid var(--elevated-background-color);
  text-align: center;
  gap: 1.5rem;

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: baseline;
}

.xIcon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: none;
  background-color: var(--elevated-background-color);
  width: 2.25em;
  height: 2.25em;
  padding: 0.5em;
  border-radius: 50%;
  cursor: pointer;
  color: var(--label-text-color);
  :hover {
    opacity: 0.8;
  }
  svg {
    color: var(--primary-text-color);
  }
}

.infoText {
  line-height: 1.4;
  color: var(--label-text-color);
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
