@use "@/styles/mixins/max-root-width";
@use "@/styles/mixins/media-queries" as *;

.container {
  @include max-root-width.limit;
  width: 100%;
}

.title {
  text-transform: uppercase;
  margin-bottom: 1.25rem;
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.5rem;

  @include tablet-portrait-and-up {
    grid-template-columns: 1fr 1fr;
  }

  @include desktop-and-up {
    grid-template-columns: 1fr 1fr 1fr;
  }
}