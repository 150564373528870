@use "@/styles/mixins/media-queries" as *;
@use "@/styles/mixins/line-clamp" as *;
@use "@/styles/typography/title-bold" as *;
@use "@/styles/typography/title-medium" as *;
@use "@/styles/mixins/ellipsis" as *;

.videoTeaser {
  --transition-timing: 300ms;
  text-decoration: none;
  color: var(--primary-text-color);

  &:visited {
    color: var(--primary-text-color);
  }

  @media (hover: hover) {
    &:hover {
      .videoTeaserPlayVideoIcon {
        visibility: visible;
        opacity: 1;
        transition: opacity var(--transition-timing) ease-in-out;
      }
    }
  }
}

.videoTeaserContent {
  display: grid;
  row-gap: 0.625rem;

  @include tablet-portrait-only {
    row-gap: 1rem;
  }
}

.videoTeaserImageWrapper {
  position: relative;
}

.videoTeaserImage {
  border-radius: var(--rounded-corner);
}

.videoTeaserDuration {
  position: absolute;
  bottom: 0;
  right: 0;
}

.videoTeaserTitle {
  @include line-clamp(2);

  @include tablet-portrait-only {
    @include line-clamp(3);
  }
}

.videoTeaserInfo {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  @include ellipsis;
  @extend .title-8-medium;
}

.videoTeaserPublisher {
  min-width: 0;
  @include ellipsis;
}

.videoTeaserPublishedTime {
  color: var(--label-text-color);
}

.videoTeaserPlayVideoIcon {
  --icon-size: 3.75rem;
  height: var(--icon-size);
  width: var(--icon-size);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 0px 1.25rem rgba(64, 62, 74, 0.2));
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s var(--transition-timing),
  opacity var(--transition-timing) ease-in-out;
}
