@use "@/styles/typography/title-bold" as *;
@use "@/styles/typography/label" as *;

.blockquote__icon {
  width: 1.5em;
  height: 1.125em;
}

.blockquote__textContainer {
  margin-top: 0.3125em;
}

.blockquote__text {
  @extend .title-6-bold;
  word-break: break-word;
}

.blockquote__author {
  margin-top: 0.625em;
}

.label {
  @include label;
}