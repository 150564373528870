.container {
  text-transform: uppercase;
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
}

/*
 * The values use for the border radius are the same that the video player has
 * I add them to the botton corners to make it all symmetrical
 */
.videoPlayerWrapper {
  position: relative;
  margin-top: 1.25rem;
  aspect-ratio: 1.56; // Aspect radio defined by the video player team the height = width*0.56
}

.videoPlayer {
  position: relative;
  z-index: var(--embedded-video-player-index);
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
}

.loading {
  border: 1px solid var(--divider-color);
  border-radius: var(--rounded-corner);
  position: absolute;
  display: grid;
  place-content: center;
  width: 100%;
  top:0;
  left:0;
  background-color: var(--primary-background-color);
  aspect-ratio: 16 / 9;
  border-radius: 10px;
}
