// adds fake borders which does not affect the element size
@mixin fake-borders-pseudo-element($width, $color) {
  content: "";
  position: absolute;
  z-index: -1;
  top: -#{$width};
  left: -#{$width};
  right: -#{$width};
  bottom: -#{$width};
  border: $width solid $color;
  border-radius: calc(var(--rounded-corner) + #{$width});
}