@use "@/styles/typography/text" as *;

.articleParagraph {
  @extend .text-1;
  overflow: hidden;

  & > * {
    word-break: break-word;
  }

  a {
    color: var(--accent-color);
    text-underline-offset: 0.25em;
    -webkit-text-decoration-color: var(--divider-color);
    text-decoration-color: var(--divider-color);

    @media (hover: hover) {
      &:hover {
        text-decoration-line: none;
      }
    }

    &:visited {
      color: var(--label-text-color);
    }
  }

  iframe {
    // we do not control inner html which can have iframes with a width attribute
    max-width: 100%;
  }

  img {
    // we don't expect 3rd party images inside paragraphs
    display: none;
  }
}