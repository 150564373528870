@use "../mixins/media-queries" as *;

.xpaContainer {
  --containers-vertical-gap: 1.5rem;

  @include tablet-portrait-and-up {
    --containers-vertical-gap: 2rem;
  }

  *[class*="xpaLayoutContainerGridItemComponents"] {
    & > * + * {
      margin-top: 2rem;
    }

    & > h2 + section {
      margin-top: 1rem;
    }
  }

  :global {
    .xpaLayoutContainer:first-of-type {
      [class*="xpaLayoutContainerComponentResolver"]:first-child {
        padding-top: 0;
      }
    }

    @include mobile-only {
      .xpaLayoutContainerFullWidth--knockoutTree {
        margin-top: 1rem;
      }
    }

    .xpaLayoutContainerFullWidth--entityTitle {
      margin-top: 0;
    }

    .xpaLayoutContainerFullWidth--articleHeroBanner {
      margin-top: 0;

      & + * {
        @include desktop-and-up {
          margin-top: 4rem;
        }
      }
    }
  }
}

.matchCardComponentWrapper {
  width: 100%;
  max-width: 22.5rem;
  place-self: center;
}
