@use "@/styles/mixins/max-root-width" as *;

.container {
  border-top: 1px solid var(--divider-color);
  border-bottom: 1px solid var(--divider-color);
  padding: 1.875rem 0 0.625rem;
}

.title {
  @include limit;
  text-transform: uppercase;
}

.links {
  min-width: 0;
}